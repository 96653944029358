import {
  Box,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";

const ScreenPage = () => {
  return (
    <Stack sx={{ flexGrow: 1, py: 3, alignItems: "center" }}>
      <Stack sx={{ mb: 6 }}>
        <Typography
          variant="h4"
          color="primary"
          sx={{
            fontFamily: "'Paytone One', sans- serif",
            mb: "15px",
          }}
        >
          Welcome to this demo mock-up survey
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
            mb: "15px",
            fontWeight: 300,
            "& b, strong": {
              fontWeight: 400,
            },
          }}
        >
          The main purpose of this survey is to test out some existing and new
          features.
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
            mb: "15px",
            fontWeight: 300,
            "& b, strong": {
              fontWeight: 400,
            },
          }}
        >
          This is a sample INTRO page.
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
            mb: "15px",
            fontWeight: 300,
            "& b, strong": {
              fontWeight: 400,
            },
          }}
        >
          This survey is meant to be used internally at SlashData Ltd
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
            mb: "15px",
            fontWeight: 300,
            "& b, strong": {
              fontWeight: 400,
            },
          }}
        >
          Any question please contact with the Tech Team.
        </Typography>
      </Stack>

      <FormControl
        sx={{
          alignSelf: "center",
          mb: 1,
          "& label": {
            ml: "0",
          },
          "& .MuiButtonBase-root": { flexShrink: 0 },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="checked"
              sx={{
                color: "unchecked.main",
              }}
            />
          }
          label={
            <Typography variant="body2">
              Agree to Survey's{" "}
              <Box
                component="a"
                href="https://slashdata.co"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: "text.primary",
                }}
              >
                Terms & Conditions
              </Box>
            </Typography>
          }
        />
      </FormControl>

      <Fab
        variant="extended"
        size="large"
        color="primary"
        sx={{
          alignSelf: "center",
          width: { xs: "100%", sm: "362px" },
          height: "92px",
          borderRadius: "46px",
          color: "#ffffff",
          fontSize: "20px",
          fontWeight: 900,
          "&:hover": {
            backgroundColor: "secondary.main",
          },
        }}
      >
        Start now
      </Fab>
    </Stack>
  );
};

export default ScreenPage;
