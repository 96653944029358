export const DANGER_ZONE_ENVIRONMENTS = ["development", "staging"];

export const AVAILABLE_LOCALES = [
  {
    id: "ar",
    value: "Arabic",
  },
  {
    id: "fr",
    value: "French",
  },
  {
    id: "de",
    value: "German",
  },
  {
    id: "id",
    value: "Indonesian",
  },
  {
    id: "ja",
    value: "Japanese",
  },
  {
    id: "ko",
    value: "Korean",
  },
  {
    id: "pt",
    value: "Portuguese",
  },
  {
    id: "ru",
    value: "Russian",
  },
  {
    id: "es",
    value: "Spanish",
  },
  {
    id: "tw",
    value: "Traditional Chinese",
  },
  {
    id: "tr",
    value: "Turkish",
  },
  {
    id: "vi",
    value: "Vietnamese",
  },
  {
    id: "zh",
    value: "Simplified Chinese",
  },
];

export const TRANSLATION_STATUS_DRAFT = "draft";
export const TRANSLATION_STATUS_REVIEWED = "reviewed";
