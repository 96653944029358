import { Alert, Box, Button, CircularProgress, Stack } from "@mui/material";
import useBaseStore from "../../store/base";
import { API } from "aws-amplify";
import getJwt from "../../helpers/getJwt";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import useMediaStore from "../../store/media";
import { useMount } from "react-use";

const UploadFile = ({ file, preview, onClose }) => {
  const [loader, setLoader] = useState(false);
  const [foundLoader, setFoundLoader] = useState(true);
  const [found, setFound] = useState(true);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const fetchFiles = useMediaStore((state) => state.fetchFiles);

  useMount(async () => {
    try {
      const jwt = await getJwt();

      const { found } = await API.get("surveys", `/media/${file.name}`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setFound(found);

      setFoundLoader(false);
    } catch (e) {
      console.error(e);
    }
  });

  const upload = async () => {
    try {
      setLoader(true);

      const jwt = await getJwt();

      const { presignedUrl } = await API.post("surveys", `/media`, {
        headers: { Authorization: `Bearer ${jwt}` },
        body: {
          fileName: file.name,
          fileType: file.type,
        },
      });

      await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      await fetchFiles({ refresh: true });

      setSnackbar({
        open: true,
        type: "success",
        message: "File was uploaded",
      });

      onClose();
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  return (
    <Stack sx={{ flexGrow: 1, gap: 2 }}>
      {foundLoader ? (
        <CircularProgress
          thickness={2}
          size="50px"
          sx={{ mx: "auto", my: "50px" }}
        />
      ) : (
        <>
          <Alert severity="info">{file.name}</Alert>

          {found && (
            <Alert severity="warning">
              There is already a file with name '{file.name}'
            </Alert>
          )}

          <Stack
            sx={{
              flex: 1,
              backgroundColor: "white",
              backgroundImage:
                "linear-gradient(45deg, #e0e0e0 25%, transparent 25%), " +
                "linear-gradient(-45deg, #e0e0e0 25%, transparent 25%), " +
                "linear-gradient(45deg, transparent 75%, #e0e0e0 75%), " +
                "linear-gradient(-45deg, transparent 75%, #e0e0e0 75%)",
              backgroundSize: "10px 10px",
              backgroundPosition: "0 0, 0 5px, 5px -5px, -5px 0px",
              borderRadius: "5px",
            }}
          >
            <Box
              component="img"
              src={preview}
              sx={{
                height: "300px",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              pt: 2,
            }}
          >
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>

            <LoadingButton
              loading={loader}
              color="primary"
              variant="contained"
              onClick={upload}
            >
              Upload
            </LoadingButton>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default UploadFile;
