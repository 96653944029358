import {
  Alert,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ThemeProvider,
} from "@mui/material";
import useThemesStore, {
  SCREEN_PAGE,
  SCREEN_QUESTION,
  SCREENS,
} from "../../../../store/themes";
import { shallow } from "zustand/shallow";
import Navbar from "./Navbar";
import ScreenPage from "./ScreenPage";
import ScreenQuestion from "./ScreenQuestion";

const Preview = () => {
  const [currentPreview, setCurrentPreview, currentTheme] = useThemesStore(
    (state) => [
      state.currentPreview,
      state.setCurrentPreview,
      state.currentTheme,
    ],
    shallow
  );

  return (
    <Stack sx={{ flexGrow: "1", p: 3 }}>
      <Stack direction="row" sx={{ alignItems: "center", gap: 2, mb: 2 }}>
        <FormControl size="small" sx={{ width: "150px" }}>
          <InputLabel size="small">Screen</InputLabel>

          <Select
            value={currentPreview}
            label="Screen"
            size="small"
            onChange={(e) => setCurrentPreview(e.target.value)}
          >
            {SCREENS.map((page) => (
              <MenuItem key={page.value} value={page.value}>
                {page.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Alert severity="info" sx={{ fontWeight: "500" }}>
          Preview screens are simulated and may not exactly match the final
          version.
        </Alert>
      </Stack>

      <ThemeProvider
        theme={createTheme({
          palette: currentTheme.palette,
        })}
      >
        <>
          <Navbar />

          {currentPreview === SCREEN_PAGE && <ScreenPage />}

          {currentPreview === SCREEN_QUESTION && <ScreenQuestion />}
        </>
      </ThemeProvider>
    </Stack>
  );
};

export default Preview;
