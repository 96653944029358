import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import useBaseStore from "../../store/base";
import { shallow } from "zustand/shallow";
import { useEffect, useMemo, useRef, useState } from "react";
import useMediaStore from "../../store/media";
import { useMount } from "react-use";
import MediaCard from "./MediaCard";
import MediaDetails from "./MediaDetails";
import { Close } from "@mui/icons-material";

const DATE_DESC = "DATE_DESC";
const DATE_ASC = "DATE_ASC";
const NAME_DESC = "NAME_DESC";
const NAME_ASC = "NAME_ASC";

const SORT_BY = [
  {
    value: DATE_DESC,
    label: "Newest First",
  },
  {
    value: DATE_ASC,
    label: "Oldest First",
  },
  {
    value: NAME_ASC,
    label: "Name (A-Z)",
  },
  {
    value: NAME_DESC,
    label: "Name (Z-A)",
  },
];

const Media = () => {
  const [dialog, setDialog, setSnackbar] = useBaseStore(
    (state) => [state.dialog, state.setDialog, state.setSnackbar],
    shallow
  );
  const [files, fetchFiles] = useMediaStore(
    (state) => [state.files, state.fetchFiles],
    shallow
  );

  const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [fileDetails, setFileDetails] = useState(null);
  const [sortBy, setSortBy] = useState(DATE_DESC);
  const [search, setSearch] = useState("");

  const filteredFiles = useMemo(() => {
    // Step 1: Get the original file list
    let result = files?.Contents ? [...files.Contents] : []; // Create a new array to avoid modifying the original

    // Step 2: Filter based on search query
    if (search) {
      result = result.filter((file) =>
        file.Key.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Step 3: Sort based on sortBy
    if (sortBy === DATE_DESC) {
      result.sort(
        (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
      );
    } else if (sortBy === DATE_ASC) {
      result.sort(
        (a, b) => new Date(a.LastModified) - new Date(b.LastModified)
      );
    } else if (sortBy === NAME_DESC) {
      result.sort((a, b) => b.Key.localeCompare(a.Key));
    } else if (sortBy === NAME_ASC) {
      result.sort((a, b) => a.Key.localeCompare(b.Key));
    }

    return result;
  }, [files, sortBy, search]);

  useMount(async () => {
    try {
      await fetchFiles();
    } catch (e) {
      console.log(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setDialog({
        open: true,
        component: "uploadFile",
        props: {
          file,
          preview: reader.result,
        },
      });
    };
  };

  useEffect(() => {
    if (!dialog.open && fileInputRef.current) fileInputRef.current.value = null;
  }, [dialog]);

  return (
    <Stack sx={{ flexGrow: 1 }}>
      {loader ? (
        <CircularProgress thickness={2} size="80px" sx={{ m: "auto" }} />
      ) : (
        <>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Stack sx={{ flex: 1 }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ alignSelf: "start" }}
                onClick={() => fileInputRef.current.click()}
              >
                New File
              </Button>

              <Box
                component="input"
                type="file"
                ref={fileInputRef}
                accept=".jpg,.jpeg,.png,.svg"
                sx={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Stack>

            <Stack
              sx={{ flex: 1, alignItems: "center", gap: 1 }}
              direction="row"
            >
              <TextField
                label="Search a file"
                variant="outlined"
                size="small"
                sx={{ flexGrow: 1 }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              {search && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setSearch("")}
                >
                  <Close />
                </IconButton>
              )}
            </Stack>

            <Stack sx={{ flex: 1 }}>
              <FormControl
                sx={{ alignSelf: "end", width: "200px" }}
                size="small"
              >
                <InputLabel>Sort</InputLabel>

                <Select
                  label="Sort"
                  size="small"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  {SORT_BY.map((sort) => (
                    <MenuItem value={sort.value} key={sort.value}>
                      {sort.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Stack sx={{ flexGrow: 1 }} direction="row">
            <Stack
              sx={{
                flex: 1,
                pt: 4,
              }}
            >
              <Stack
                sx={{
                  overflowY: "auto",
                  flexGrow: 1,
                  height: "1px",
                  pr: 2,
                }}
              >
                <Grid container spacing={2}>
                  {filteredFiles?.map((file) => (
                    <Grid item xs={4} key={file.Key}>
                      <MediaCard
                        file={file}
                        onClick={(fileType) =>
                          setFileDetails({ file, fileType })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>

            <Stack
              sx={{
                width: "25%",
                pt: 2,
                pl: 2,
                borderLeft: fileDetails ? "1px solid #e0e0e0" : "none",
              }}
            >
              {fileDetails && (
                <MediaDetails
                  {...fileDetails}
                  onClose={() => setFileDetails(null)}
                />
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Media;
