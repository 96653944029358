import { Navigate, createBrowserRouter } from "react-router-dom";
import AuthGuard from "./AuthGuard";

import App from "../App";
import Login from "../views/Login";
import Unauthorized from "../views/Unauthorized";
import surveysRoutes from "./surveys";
import themesRoutes from "./themes";
import mediaRoutes from "./media";

export default createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/login",
        element: (
          <AuthGuard requiresAuth={false}>
            <Login />
          </AuthGuard>
        ),
      },
      // ============ SURVEYS ============
      ...surveysRoutes,
      // ============ THEMES ============
      ...themesRoutes,
      // ============ MEDIA ============
      ...mediaRoutes,
      {
        path: "/unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "*",
        element: <Navigate to="/surveys" />,
      },
    ],
  },
]);
