import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Close, Delete, Edit, ContentCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useThemesStore from "../../store/themes";
import useBaseStore from "../../store/base";
import { useMount } from "react-use";
import { shallow } from "zustand/shallow";

const Themes = () => {
  const [themes, fetchThemes] = useThemesStore(
    (state) => [state.themes, state.fetchThemes],
    shallow
  );
  const [setDialog, setSnackbar] = useBaseStore(
    (state) => [state.setDialog, state.setSnackbar],
    shallow
  );
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);

  useMount(async () => {
    try {
      await fetchThemes();
    } catch (e) {
      console.log(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  });

  const filteredThemes = useMemo(() => {
    if (!search) return themes;

    return themes.filter((theme) =>
      theme.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [themes, search]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {loader ? (
        <CircularProgress thickness={2} size="80px" sx={{ m: "auto" }} />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  setDialog({
                    open: true,
                    component: "createTheme",
                  })
                }
              >
                Create Theme
              </Button>
            </Grid>

            <Grid item xs={6} sx={{ display: "flex" }}>
              <Stack
                direction="row"
                gap={1}
                sx={{ alignItems: "center", flexGrow: 1 }}
              >
                <TextField
                  label="Search a theme"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                {search && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setSearch("")}
                  >
                    <Close />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>

          <DataGrid
            disableSelectionOnClick
            disableColumnMenu
            getRowId={(row) => row._id}
            sx={{
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "transparent",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={filteredThemes}
            columns={[
              { field: "name", headerName: "Name", flex: 1 },
              {
                field: "createdAt",
                headerName: "Creation Date",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {dayjs.utc(params.value).format("MMM DD YYYY | HH:mm")}
                    </div>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Actions",
                flex: 1,
                sortable: false,
                renderCell: (params) => {
                  return (
                    <Stack
                      direction="row"
                      gap={2}
                      sx={{ alignItems: "center" }}
                    >
                      <CopyToClipboard
                        text={params.row._id}
                        onCopy={() =>
                          setSnackbar({
                            open: true,
                            type: "success",
                            message: "Theme ID Copied!",
                          })
                        }
                      >
                        <Tooltip title="Copy Theme ID">
                          <IconButton size="small">
                            <ContentCopy fontSize="10px" />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>

                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<Edit />}
                        component={Link}
                        to={`/themes/${params.row._id}`}
                      >
                        Edit
                      </Button>

                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<Delete />}
                        color="error"
                        onClick={() =>
                          setDialog({
                            open: true,
                            component: "deleteTheme",
                            props: {
                              theme: params.row,
                            },
                          })
                        }
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </>
      )}
    </Stack>
  );
};

export default Themes;
