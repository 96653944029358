import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import getJwt from "../helpers/getJwt";
import { API } from "aws-amplify";

const useMediaStore = create()(
  immer((set, getState) => ({
    files: null,
    filesFetched: false,
    fetchFiles: async ({ refresh } = { refresh: false }) => {
      if (getState().filesFetched && !refresh) return getState().files;

      const jwt = await getJwt();

      const files = await API.get("surveys", "/media", {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      set((state) => {
        state.files = files;
        state.filesFetched = true;
      });

      return files;
    },
  }))
);

export default useMediaStore;
