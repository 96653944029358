import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import useThemesStore from "../../../../store/themes";
import { shallow } from "zustand/shallow";
import { useMemo } from "react";
import { AccountCircle, Keyboard, Save } from "@mui/icons-material";

const Navbar = () => {
  const [currentTheme] = useThemesStore(
    (state) => [state.currentTheme],
    shallow
  );

  const logoSrc = useMemo(() => {
    return currentTheme?.assets?.logo?.url
      ? `${process.env.REACT_APP_S3_URL}/${currentTheme?.assets?.logo?.url}`
      : null;
  }, [currentTheme]);

  return (
    <AppBar
      color="inherit"
      position="static"
      sx={{
        boxShadow: "0px 6px 21px 0 rgba(154, 170, 176, 0.17)",
      }}
    >
      <Toolbar
        sx={{
          height: "67px",
          px: "100px!important",
        }}
      >
        {logoSrc && (
          <Box component="img" src={logoSrc} sx={{ maxHeight: "50px" }} />
        )}

        <Stack
          direction="row"
          sx={{ alignItems: "center", ml: "auto" }}
          gap={"5px"}
        >
          <IconButton size="small" color="primary">
            <Keyboard />
          </IconButton>

          <IconButton size="small" color="primary">
            <Save />
          </IconButton>

          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                color: "#b0c0da",
                width: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              test@slashdata.co
            </Typography>

            <IconButton
              size="small"
              disableRipple
              sx={{
                color: "#b0c0da",
              }}
            >
              <AccountCircle />
            </IconButton>
          </Stack>

          <Select
            size="small"
            value="en"
            sx={{
              "& .MuiSelect-select": {
                fontSize: "14px",
                p: "5px 15px",
                pr: "32px",
              },
              color: "primary.main",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main",
                borderWidth: "2px",
              },
            }}
          >
            <MenuItem value="en">English</MenuItem>

            <MenuItem value="es">Spanish</MenuItem>

            <MenuItem value="fr">French</MenuItem>
          </Select>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
