import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import getJwt from "../../helpers/getJwt";
import useBaseStore from "../../store/base";
import useThemesStore from "../../store/themes";

const CreateTheme = ({ onClose }) => {
  const [name, setName] = useState("");
  const [nameExists, setNameExists] = useState(false);
  const [loader, setLoader] = useState(false);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const fetchThemes = useThemesStore((state) => state.fetchThemes);

  useEffect(() => {
    setNameExists(false);
  }, [name]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    try {
      const jwt = await getJwt();

      await API.post("surveys", "/themes", {
        body: {
          name,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      await fetchThemes({ refresh: true });

      setSnackbar({
        open: true,
        type: "success",
        message: "Theme Created!",
      });

      onClose();
    } catch (e) {
      if (e.response.status === 400) {
        setNameExists(true);
      } else {
        console.log(e);

        setSnackbar({
          open: true,
          type: "error",
          message: "Something went wrong",
        });
      }
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={2}
    >
      <Typography variant="h6">Create Theme</Typography>

      <Stack
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: "500px" }}
        gap={2}
      >
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={nameExists}
          helperText={nameExists ? "theme with this name already exists." : ""}
        />

        {loader ? (
          <CircularProgress sx={{ alignSelf: "center" }} />
        ) : (
          <Button variant="contained" type="submit" disabled={!name}>
            Create Theme
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default CreateTheme;
