import Media from "../views/media/Media";
import AuthGuard from "./AuthGuard";

const routes = [
  {
    path: "/media",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <Media />
      </AuthGuard>
    ),
  },
];

export default routes;
