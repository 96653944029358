import {
  Box,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import useBaseStore from "../../store/base";
import CopyToClipboard from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import dayjs from "dayjs";

const MediaCard = ({ file, onClick }) => {
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const MediaCardRef = useRef(null);

  const fileType = useMemo(() => {
    const match = file.Key.match(/\.([^.]+)$/);

    if (match) {
      return match[1];
    }

    return "";
  }, [file]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once it's visible
        }
      },
      { threshold: 0.1 }
    );

    if (MediaCardRef.current) observer.observe(MediaCardRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Stack
      ref={MediaCardRef}
      sx={{
        ...(imageLoaded && {
          p: 2,
          border: "1px solid #e0e0e0",
          borderRadius: "5px",
          cursor: "pointer",
          gap: 1,

          "&:hover": {
            border: "1px solid #616161",
          },
        }),
      }}
      onClick={() => onClick(fileType)}
    >
      <Stack
        sx={{
          backgroundColor: "white",
          backgroundImage:
            "linear-gradient(45deg, #e0e0e0 25%, transparent 25%), " +
            "linear-gradient(-45deg, #e0e0e0 25%, transparent 25%), " +
            "linear-gradient(45deg, transparent 75%, #e0e0e0 75%), " +
            "linear-gradient(-45deg, transparent 75%, #e0e0e0 75%)",
          backgroundSize: "10px 10px",
          backgroundPosition: "0 0, 0 5px, 5px -5px, -5px 0px",
          borderRadius: "5px",
        }}
      >
        {isVisible && (
          <Box
            component="img"
            sx={{
              objectFit: "contain",
              height: "150px",
              display: imageLoaded ? "block" : "none",
            }}
            onLoad={() => setImageLoaded(true)}
            src={`${process.env.REACT_APP_S3_URL}/${file.Key}`}
          />
        )}
      </Stack>

      {!imageLoaded && <Skeleton variant="rounded" height={150} />}

      <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
        <CopyToClipboard
          text={file.Key}
          onCopy={() =>
            setSnackbar({
              open: true,
              type: "success",
              message: "File key Copied!",
            })
          }
        >
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Copy File key">
              <IconButton size="small">
                <ContentCopy fontSize="10px" />
              </IconButton>
            </Tooltip>
          </div>
        </CopyToClipboard>

        <Typography
          variant="body1"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "500",
          }}
        >
          {file.Key}
        </Typography>
      </Stack>

      <Stack sx={{ alignItems: "center", gap: "4px" }} direction="row">
        <Chip
          label={fileType}
          size="small"
          sx={{ alignSelf: "start", fontWeight: "500" }}
        />

        <Chip
          label={dayjs(file.LastModified).format("MMM DD, YYYY HH:mm")}
          size="small"
          sx={{ alignSelf: "start", fontWeight: "500" }}
        />
      </Stack>
    </Stack>
  );
};

export default MediaCard;
