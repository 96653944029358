import { useState } from "react";
import { useMount, useUnmount } from "react-use";
import useThemesStore from "../../../store/themes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, Stack } from "@mui/material";
import { shallow } from "zustand/shallow";
import EditThemeForm from "./EditThemeForm";
import { ArrowBackIosNew } from "@mui/icons-material";
import Preview from "./preview/Preview";

const EditTheme = () => {
  const [loader, setLoader] = useState(true);
  const [fetchThemes, setCurrentTheme, setInitialCurrentTheme] = useThemesStore(
    (state) => [
      state.fetchThemes,
      state.setCurrentTheme,
      state.setInitialCurrentTheme,
    ],
    shallow
  );
  const { id } = useParams();
  const navigate = useNavigate();

  useMount(async () => {
    let themes;

    try {
      themes = await fetchThemes();
    } catch (e) {
      console.log(e);

      themes = [];
    }

    const found = themes.find((theme) => theme._id === id);

    if (!found) navigate("/themes");

    setCurrentTheme(found);
    setInitialCurrentTheme(found);

    setLoader(false);
  });

  useUnmount(() => {
    setCurrentTheme(null);
    setInitialCurrentTheme(null);
  });

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
      direction="row"
    >
      {loader ? (
        <CircularProgress thickness={2} size="80px" sx={{ m: "auto" }} />
      ) : (
        <>
          <Stack
            sx={{
              width: "300px",
              flexShrink: 0,
              borderRight: "1px solid",
              borderColor: "#bdbdbd",
              pr: 3,
            }}
          >
            <Button
              startIcon={<ArrowBackIosNew />}
              component={Link}
              to="/themes"
              sx={{ alignSelf: "start", mb: 3 }}
            >
              Back
            </Button>

            <EditThemeForm />
          </Stack>

          <Stack sx={{ flex: 1 }}>
            <Preview />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default EditTheme;
