import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

const ScreenQuestion = () => {
  const options = [
    "Basic features",
    "Piping checkbox list",
    "Multi-piping checkbox list",
    "Exclusive piping checkbox list",
    "Piping radio list",
  ];

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Stack
        sx={{
          pt: "36px",
          borderBottom: "1px solid #dce3ee",
          px: "100px",
          pb: "20px",
        }}
        gap="5px"
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "end",
            gap: 1,
            lineHeight: "normal",
            "& .required": {
              color: "error.main",
              fontSize: "20px",
              fontWeight: "bold",
              display: "inline-block",
            },
          }}
        >
          Select the features you'd like to test <span class="required">*</span>
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "13px", sm: "16px" },
            color: "text.secondary",
            lineHeight: "normal",
          }}
        >
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem iste
          facere perspiciatis, perferendis maiores quam mollitia vitae
          consequatur reprehenderit veritatis.
        </Typography>
      </Stack>

      <Stack
        sx={{
          pt: { xs: "0", md: "50px" },
          flexGrow: 1,
        }}
      >
        {options.map((option, i) => (
          <Stack
            key={`row-${i}`}
            direction="row"
            sx={{
              px: "100px",
              "&:nth-of-type(odd)": {
                backgroundColor: "stripe.main",
              },
            }}
          >
            <Stack
              direction="row"
              component="label"
              sx={{
                minHeight: "4em",
                alignItems: "center",
                padding: "4px",
                cursor: "pointer",
                userSelect: "none",
              }}
              gap={1}
            >
              <Checkbox
                color="checked"
                sx={{
                  color: "unchecked.main",
                }}
              />

              <Box sx={{ fontSize: "16px" }}>{option}</Box>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          px: "100px",
          py: 5,
          mt: "auto",
        }}
        gap={"200px"}
      >
        <Button
          color="primary"
          variant="outlined"
          sx={{
            width: "80px",
            height: "50px",
            borderWidth: "2px",
            borderColor: "primary.main",
            borderRadius: "10px",
            "&:hover": {
              borderWidth: "2px",
              backgroundColor: "secondary.main",
              borderColor: "secondary.main",
              color: "white",
            },
          }}
        >
          <ArrowBack />
        </Button>

        <Button
          color="primary"
          variant="contained"
          sx={{
            width: "80px",
            height: "50px",
            borderRadius: "10px",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "secondary.main",
              boxShadow: "none",
            },
          }}
        >
          <ArrowForward sx={{ color: "white" }} />
        </Button>
      </Stack>

      <Stack
        direction="row"
        sx={{
          borderTop: "1px solid",
          borderColor: "grey.200",
          px: "100px",
          alignItems: "center",
          py: 2,
        }}
        gap={2}
      >
        <LinearProgress
          color="primary"
          variant="determinate"
          value={35}
          sx={{
            height: "10px",
            flexGrow: 1,
            width: "100%",
            backgroundColor: "stripe.main",
          }}
        />

        <Typography
          color="primary"
          sx={{
            fontSize: { xs: "16px", lg: "20px" },
          }}
        >
          35%
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScreenQuestion;
