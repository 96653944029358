import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import getJwt from "../helpers/getJwt";
import { API } from "aws-amplify";
import { set as lodashSet } from "lodash";

export const SCREEN_PAGE = "SCREEN_PAGE";
export const SCREEN_QUESTION = "SCREEN_QUESTION";

export const SCREENS = [
  {
    value: SCREEN_PAGE,
    label: "Page",
  },
  {
    value: SCREEN_QUESTION,
    label: "Question",
  },
];

const useThemesStore = create()(
  immer((set, getState) => ({
    themes: [],
    themesFetched: null,
    currentTheme: null,
    initialCurrentTheme: null,
    currentPreview: SCREEN_PAGE,
    fetchThemes: async ({ refresh } = { refresh: false }) => {
      if (getState().themesFetched && !refresh) return getState().themes;

      const jwt = await getJwt();

      const themes = await API.get("surveys", "/themes", {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      set((state) => {
        state.themes = themes;
        state.themesFetched = true;
      });

      return themes;
    },
    setThemes: (themes) =>
      set((state) => {
        state.themes = themes;
      }),
    setCurrentTheme: (currentTheme) =>
      set((state) => {
        state.currentTheme = currentTheme;
      }),
    setInitialCurrentTheme: (initialCurrentTheme) =>
      set((state) => {
        state.initialCurrentTheme = initialCurrentTheme;
      }),
    setCurrentPreview: (currentPreview) =>
      set((state) => {
        state.currentPreview = currentPreview;
      }),
    editCurrentTheme: ({ path, value }) =>
      set((state) => {
        lodashSet(state.currentTheme, path, value);
      }),
  }))
);

export default useThemesStore;
