import { MuiColorInput } from "mui-color-input";
import { shallow } from "zustand/shallow";
import useThemesStore from "../../../store/themes";
import { useEffect, useMemo, useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { filter, includes, keys, map } from "lodash";
import { LoadingButton } from "@mui/lab";
import useBaseStore from "../../../store/base";
import getJwt from "../../../helpers/getJwt";
import { API } from "aws-amplify";

const THEME_COLOR_LABELS = {
  primary: "Primary",
  secondary: "Secondary",
  stripe: "Stripe",
  checked: "Checkbox",
  unchecked: "Unchecked",
};

const THEME_ASSET_LABELS = {
  logo: "Logo",
};

const EditThemeForm = () => {
  const [
    currentTheme,
    editCurrentTheme,
    initialCurrentTheme,
    setCurrentTheme,
    setInitialCurrentTheme,
    fetchThemes,
  ] = useThemesStore(
    (state) => [
      state.currentTheme,
      state.editCurrentTheme,
      state.initialCurrentTheme,
      state.setCurrentTheme,
      state.setInitialCurrentTheme,
      state.fetchThemes,
    ],
    shallow
  );

  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [nameExists, setNameExists] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setNameExists(false);
  }, [currentTheme.name]);

  const hasChanges = useMemo(() => {
    return JSON.stringify(currentTheme) !== JSON.stringify(initialCurrentTheme);
  }, [initialCurrentTheme, currentTheme]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoader(true);

      const jwt = await getJwt();

      const updatedTheme = await API.patch(
        "surveys",
        `/themes/${currentTheme._id}`,
        {
          body: currentTheme,
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );

      setCurrentTheme(updatedTheme);
      setInitialCurrentTheme(updatedTheme);

      await fetchThemes({ refresh: true });

      setSnackbar({
        open: true,
        type: "success",
        message: "Theme Updated!",
      });
    } catch (e) {
      if (e.response.status === 400) {
        setNameExists(true);
      } else {
        console.log(e);

        setSnackbar({
          open: true,
          type: "error",
          message: "Something went wrong",
        });
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <Stack gap={2} sx={{ flexGrow: 1 }} component="form" onSubmit={onSubmit}>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
          mb: 2,
        }}
      >
        Edit Theme
      </Typography>

      <TextField
        label="Name"
        variant="outlined"
        value={currentTheme.name}
        onChange={(e) =>
          editCurrentTheme({ path: "name", value: e.target.value })
        }
        error={nameExists}
        helperText={nameExists ? "theme with this name already exists." : ""}
      />

      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
        }}
      >
        Palette
      </Typography>

      {map(
        filter(keys(currentTheme.palette), (key) =>
          includes(keys(THEME_COLOR_LABELS), key)
        ),
        (key) => (
          <MuiColorInput
            key={key}
            label={THEME_COLOR_LABELS[key]}
            size="small"
            format="hex"
            value={currentTheme?.palette?.[key]?.main || ""}
            onChange={(color) =>
              editCurrentTheme({ path: `palette.${key}.main`, value: color })
            }
          />
        )
      )}

      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
        }}
      >
        Assets
      </Typography>

      {map(
        filter(keys(currentTheme.assets), (key) =>
          includes(keys(THEME_ASSET_LABELS), key)
        ),
        (key) => (
          <Stack
            key={key}
            direction="row"
            sx={{ alignItems: "center", gap: 1 }}
          >
            <TextField
              label={THEME_ASSET_LABELS[key]}
              variant="outlined"
              size="small"
              value={currentTheme?.assets?.[key]?.url || ""}
              onChange={(e) =>
                editCurrentTheme({
                  path: `assets.${key}.url`,
                  value: e.target.value,
                })
              }
              sx={{ flexGrow: 1 }}
            />
          </Stack>
        )
      )}

      <LoadingButton
        variant="contained"
        type="submit"
        loading={loader}
        disabled={!currentTheme.name || !hasChanges}
        sx={{ mt: "auto" }}
      >
        Save Theme
      </LoadingButton>
    </Stack>
  );
};

export default EditThemeForm;
