import { AppBar, Button, Stack, Toolbar, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import useAuthStore from "../store/auth";
import useBaseStore from "../store/base";

const Navbar = () => {
  const { user } = useAuthStore();
  const { setDialog } = useBaseStore();

  return (
    <AppBar position="static">
      <Toolbar>
        <Stack
          sx={{ flexGrow: 1, alignItems: "center" }}
          gap={1}
          direction="row"
        >
          <Typography
            component={NavLink}
            to="/"
            variant="h6"
            sx={{
              color: "inherit",
              textDecoration: "none",
              mr: "auto",
            }}
          >
            Slash Data | Survey Editor
          </Typography>

          {!user && (
            <Button color="inherit" component={NavLink} to="/login">
              Login
            </Button>
          )}

          {user && (
            <>
              {!user.unauthorized && (
                <>
                  <Button
                    component={NavLink}
                    to="/surveys"
                    color="inherit"
                    sx={{
                      "&.active": {
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                      },
                    }}
                  >
                    Surveys
                  </Button>

                  <Button
                    component={NavLink}
                    to="/themes"
                    color="inherit"
                    sx={{
                      "&.active": {
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                      },
                    }}
                  >
                    Themes
                  </Button>

                  <Button
                    component={NavLink}
                    to="/media"
                    color="inherit"
                    sx={{
                      "&.active": {
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                      },
                    }}
                  >
                    Media
                  </Button>
                </>
              )}

              <Button
                color="inherit"
                onClick={() =>
                  setDialog({
                    open: true,
                    component: "logout",
                    props: null,
                  })
                }
              >
                Logout
              </Button>
            </>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
