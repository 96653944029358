import EditTheme from "../views/themes/edit-theme/EditTheme";
import Themes from "../views/themes/Themes";
import AuthGuard from "./AuthGuard";

const routes = [
  {
    path: "/themes",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <Themes />
      </AuthGuard>
    ),
  },
  {
    path: "/themes/:id",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <EditTheme />
      </AuthGuard>
    ),
  },
];

export default routes;
