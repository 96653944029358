import { Close, ContentCopy } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import CopyToClipboard from "react-copy-to-clipboard";
import useBaseStore from "../../store/base";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import useMediaStore from "../../store/media";
import getJwt from "../../helpers/getJwt";
import { API } from "aws-amplify";

const MediaDetails = ({ file, fileType, onClose }) => {
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const fetchFiles = useMediaStore((state) => state.fetchFiles);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      setLoader(true);

      const jwt = await getJwt();

      await API.del("surveys", `/media/${file.Key}`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      await fetchFiles({ refresh: true });

      setSnackbar({
        open: true,
        type: "success",
        message: "File was deleted",
      });

      onClose();
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  return (
    <Stack sx={{ gap: 2, flexGrow: 1 }}>
      <IconButton size="small" sx={{ alignSelf: "end" }} onClick={onClose}>
        <Close />
      </IconButton>

      <Stack sx={{ flex: 1 }}>
        <Stack
          sx={{
            flex: 1,
            backgroundColor: "white",
            backgroundImage:
              "linear-gradient(45deg, #e0e0e0 25%, transparent 25%), " +
              "linear-gradient(-45deg, #e0e0e0 25%, transparent 25%), " +
              "linear-gradient(45deg, transparent 75%, #e0e0e0 75%), " +
              "linear-gradient(-45deg, transparent 75%, #e0e0e0 75%)",
            backgroundSize: "10px 10px",
            backgroundPosition: "0 0, 0 5px, 5px -5px, -5px 0px",
            borderRadius: "5px",
          }}
        >
          <Box
            component="img"
            sx={{
              objectFit: "contain",
              height: "200px",
              width: "100%",
              m: "auto",
              display: imageLoaded ? "block" : "none",
            }}
            onLoad={() => setImageLoaded(true)}
            src={`${process.env.REACT_APP_S3_URL}/${file.Key}`}
          />
        </Stack>

        {!imageLoaded && <Skeleton variant="rounded" height={200} />}
      </Stack>

      <Stack sx={{ flex: 1, gap: 1 }}>
        <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: "500" }}>
            Type:
          </Typography>

          <Chip label={fileType} size="small" sx={{ fontWeight: "500" }} />
        </Stack>

        <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: "500" }}>
            Size:
          </Typography>

          <Typography variant="body2">
            {(file.Size / (1024 * 1024)).toFixed(2)}MB
          </Typography>
        </Stack>

        <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: "500" }}>
            Uploaded on:
          </Typography>

          <Typography variant="body2">
            {dayjs(file.LastModified).format("MMM DD, YYYY HH:mm")}
          </Typography>
        </Stack>

        <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: "500", flexShrink: 0 }}>
            File key:
          </Typography>

          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "150px",
            }}
          >
            {file.Key}
          </Typography>

          <CopyToClipboard
            text={file.Key}
            onCopy={() =>
              setSnackbar({
                open: true,
                type: "success",
                message: "File key Copied!",
              })
            }
          >
            <Tooltip title="Copy File key">
              <IconButton size="small">
                <ContentCopy fontSize="10px" />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
        </Stack>
      </Stack>

      <Button
        sx={{ alignSelf: "center", mt: 2 }}
        variant="contained"
        color="error"
        size="small"
        onClick={handleClick}
      >
        Delete
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={handleClose}
      >
        <Stack sx={{ pt: "4px", px: 1, gap: 1 }}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            Are you sure you want to delete this file?
          </Typography>

          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "center" }}
            gap={1}
          >
            <Button size="small" onClick={handleClose} color="primary">
              Cancel
            </Button>

            <LoadingButton
              loading={loader}
              size="small"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </LoadingButton>
          </Stack>
        </Stack>
      </Menu>
    </Stack>
  );
};

export default MediaDetails;
